<!-- 出库复核单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 出库单号 -->
      <FormItem>
        <span>出库单号：</span>
        <Input placeholder="请选择" v-model="queryFrom.order_number" class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 客户名称 -->
      <FormItem class="marginLeft40">
        <span>客户名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.customer_id" clearable filterable class="iviewIptWidth250" @on-change="customerChange" label-in-value>
          <Option v-for="(item, index) in customerList" :value="item.customer_id" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span class="">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <FormItem class="marginLeft40" style="margin-right: 30px !important;">
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" clearable filterable placeholder="请选择" ref="name_select" v-model="queryFrom.product_id">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index" :label="item.product_name">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 出库仓库 -->
      <FormItem class="marginLeft40">
        <span>出库仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 复核时间 -->
      <FormItem class="marginLeft40">
        <span>复核时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeDate"></DatePicker>
      </FormItem>
      <!-- 复核人 -->
      <FormItem>
        <span>复核人：</span>
        <Select placeholder="请选择" v-model="queryFrom.reviewer_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="queryList(true)">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spin style="display: inline-block;" v-show="Loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          合并导出
        </span>
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录"></Table> -->
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border :row-class-name="rowClassName"></Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="queryFrom.page && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <Page v-if="queryFrom.page && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="chaxunFlag">
        <img style="margin-top: 100px;" src="../../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
    </div>

    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// import Table from '@/components/table'

export default {
  name: 'outboundReviewListRecordConsign',
  // components: {
  //   Table,
  // },
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      Loading: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
      ],
      template_id: '',
      template_type: 6,
      typeList: [],
      status: false,
      // 总条数
      total: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
        },
        {
          title: '出库单号',
          key: 'order_number',
          align: 'center',
          minWidth: 180,
          maxWidth: 300,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '出库仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '复核时间',
          key: 'create_time',
          align: 'center',
          minWidth: 150,
          maxWidth: 300,
        },
        {
          title: '复核人',
          key: 'check_user',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 160,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output(param.row.order_number)
                    },
                  },
                },
                '导出'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 || param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      // this.$Message.warning('正在开发中')
                      this.clickId = param.row.id
                      this.status = true
                      this.getTemplate()
                    },
                  },
                },
                '打印'
              ),
            ])
          },
        },
      ],
      clickId: null,
      // 表单拉下选项
      thirdList: [],
      // 客户下拉
      customerList: [],
      // 出库仓库下拉
      warehouseList: [],
      // 经手人
      userList: [],
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        product_code: '',
        order_number: '',
        customer_id: undefined,
        customer_type: '',
        warehouse_id: undefined,
        create_time_start: '',
        create_time_end: '',
        reviewer_id: '',
        product_id: '',
        product_code_array: [],
        specification_id_str: '',
      },
      paging: null,
      productList: [],
      modelList: [],
    }
  },
  mounted() {
    // this.queryList()
    this.getCustomerList()
    this.getWarehouseList()
    this.getUserList()
    this.queryProductSelect()
    this.queryProductCodeSelect()
  },
  methods: {
    queryProductSelect() {
      this.$http.get(this.$apiConsign.deliveryProductList, false).then(res => {
        this.productList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.deliveryCodeSelect, { supplier_id: this.queryFrom.supplier_id, product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$apiConsign.warehouseDeliveryDetail.url}?id=${this.clickId}`
      // for (let objName in this.queryFrom) {
      //   console.log(objName)
      //   if (this.queryFrom[objName]) {
      //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
      //   }
      // }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库复合单记录.xlsx'
        aLink.click()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 合并导出
    exportMerge() {
      let data = JSON.parse(JSON.stringify(this.queryFrom))
      delete data.page
      delete data.rows
      data.title = '1'
      this.Loading = true
      this.$http.downFile(this.$apiConsign.deliveryBatchExport, data).then(res => {
        this.Loading = false
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库复核单记录合并导出表.zip'
        aLink.click()
      })
    },
    // 导出
    output(order_number) {
      let data = {
        order_number: order_number,
        title: '1',
      }
      this.$http.downFile(this.$apiConsign.warehouseDeliveryExport, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库复核单记录导出表.xlsx'
        aLink.click()
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    // 改变日期
    changeDate(e) {
      this.queryFrom.create_time_start = e[0]
      this.queryFrom.create_time_end = e[1]
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 客户名称改变
    customerChange(e) {
      this.queryFrom.customer_type = ''
      // 判断客户类型
      if (e) {
        this.customerList.forEach(item => {
          if (item.customer_id == e.value && item.customer_name == e.label) {
            this.queryFrom.customer_type = item.customer_type
          }
        })
      } else {
        this.queryFrom.customer_type = ''
      }
    },
    // 客户名称下拉
    getCustomerList() {
      this.$http.get(this.$apiConsign.customerList).then(res => {
        this.customerList = res.data
      })
    },
    // 出库仓库下拉
    getWarehouseList() {
      this.$http.get(this.$apiConsign.permissionWareHouseList, { page: 1, rows: 1000 ,is_consign:1}).then(res => {
        this.warehouseList = res.data
      })
    },
    // 经手人下拉
    getUserList() {
      this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: '1' }).then(res => {
        this.userList = res.data
      })
    },
    // 查询列表数据
    queryList(query = false) {
      this.chaxunFlag = false
      this.tabloading = true
      if (query) {
        this.queryFrom.page = 1
      }
      if (this.queryFrom.product_code_array) {
        this.queryFrom.specification_id_str = this.queryFrom.product_code_array.join(',')
      } else {
        this.queryFrom.specification_id_str = ''
      }
      if (this.queryFrom.product_id) {
        this.productList.forEach(item => {
          if (item.product_id == this.queryFrom.product_id) {
            this.queryFrom.product_name = item.product_name
          }
        })
      } else {
        this.queryFrom.product_name = ''
      }
      this.$http.get(this.$apiConsign.warehouseDelivery, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data
        this.listData.forEach(item => {
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        })
        this.total = res.total
      })
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsOutboundReviewListRecordConsign',
        query: {
          id: row.id,
          recoil_status: row.recoil_status,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
</style>
